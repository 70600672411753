<template>
  <div>
    <loading
      :active.sync="isLoading"
      :is-full-page="false"
      :color="this.$config.LOADER.color"
      :opacity="this.$config.LOADER.opacity"
      :background-color="this.$config.LOADER.backgroundColor"
      :height="30"
      :z-index="10000"
    ></loading>
    <div class="pull-right">
      <!-- <b-form-checkbox value="true" v-model="isActive" switch ></b-form-checkbox> -->
      <!-- <b-link @click="editback(item)">засах</b-link> -->
      <b-button
        variant="info"
        size="sm"
        class="mt-0 ml-3"
        @click="editback(item)"
        >засах</b-button
      >
    </div>
    <b class="text-primary mb-0 pull-right mt-0">
      <span variant="info" v-if="item.percent == -1">Үлдсэн </span
      ><span variant="info" v-else>{{ item.percent }}%</span>
    </b>
    <div class="mt-2 mb-0">
      <small :class="item.isActive ? 'text-success' : 'text-danger'"
        ><i class="fa fa-circle mr-2"></i
      ></small>
      <b>@{{ item.sysUserId.firstname }}</b> - {{ item.sysUserId.email }}<br />
    </div>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      isLoading: false,
      isActive: this.item.isActive,
    };
  },
  props: ["item", "editback", "callback"],
  watch: {
    isActive: {
      handler(_isActive) {
        this.changeIsActive(_isActive);
      },
      deep: true,
    },
  },
  methods: {
    changeIsActive: async function(_isActive) {
      this.$data.isLoading = true;
      var response = await this.$http.post(
        this.$config.API_URL + "PurchaseWebService/condition_is_active_change",
        {
          id: this.item.id,
          isActive: _isActive,
        },
        {
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        }
      );

      this.callback();
      //console.log(response)
      this.$data.isLoading = false;
    },
  },
};
</script>
